@import '../../styles/global';

.question {

    position: relative;

    .map {
        height: calc(100vh - 170px);
        border-radius: $default-border-radius;
    }

    .title {
        position: absolute;
        border-radius: $default-border-radius;
        left: 0;
        z-index: 9999;
        background-color: white;
        width: calc(100% - 30px);
        height: fit-content;
        padding: 15px;
        box-shadow: $default-shadow;
        justify-content: center;
        
        h2 {
            font-size: $font-size-medium;
            margin: auto;
            align-items: center;
            text-align: center;
            max-width: 80%;
        }
    }
}
