@import '../../styles/global.scss';

.parent_answers {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //padding-top: 24px;
}

.answers {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 50px;
  padding-right: 16px;
  width: 500px;
}

.checked {
  border: 1px solid $primary-color;
  background-color: red;
}

.bioms {
  overflow: auto;
  max-height: 450px;
}

.donationAnswer {
    background-color: $white;
    border-radius: $default-border-radius;
    border: 1px solid $dark-gray;
    padding: 10px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    box-shadow: $default-shadow;
    height: 16px;

    input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
        font-size: $default-font-size;
        font-family: inherit;
        border: none;
        width: 64px;
        max-width: 64px;
        outline: none;
        border-bottom: 1px solid $primary-color;
        margin-left: 15px;
        margin-right: 8px;
        height: 18px;
        margin-bottom: 2px;
    }

    &:hover {
        cursor: pointer;
    }

    &.selected {
        border: 1px solid $primary-color;
    }

    
}
