@import '../../styles/global.scss';

.main {
  font-size: $font-size-big;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 2px;
  padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
}

.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  font-size: $font-size-large;
}

.child_image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-top: 50px;
  //padding-right: 256px;
  width: 40%;
  img {
    
    padding: 32px 16px 8px 8px;
  }
}

.bottom {
  font-size: $default-font-size;
  padding-top: 64px;
}

footer {
  font-size: $font-size-medium;
  padding-top: 64px;
}

