@import 'src/styles/global.scss';

.main {
  font-size: $font-size-medium;
  width: 100%;
  margin: 0 auto;

  h2 {
      font-size: $font-size-big;
    }

  .flex {
    display: flex;
  
    p {
        flex: 11;
        justify-content: left;
        display: flex;
        text-align: start;
        //font-size: $font-size-big;
    }

    //* {
    //    //flex: 1;
    //}
  }
}

.colorbar {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  font-size: $font-size-small;
  
  .left {
    width: 1.5vw;
    flex-direction: row;

    img {
      width: 1.5vw;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    height: 100%;

    .high {
      order: -1;
      padding-left: 0.5vw;
    }

    .low {
      padding-left: 0.5vw;
      margin-top: auto;
    }

  }
}

.answers {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0px 0px 0px 0px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 2px;
  padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
  justify-content: center;
}
.child_image {
  flex: 1;
  .region {
    height: 1000px;
  }
}
.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: $default-font-size;
  flex: 1;

  span {
    padding-bottom: 24px;
    padding-top: 20px;
  }
}

