@import '../../styles/global';

.radio {
  .subtitle {
    font-size: $font-size-medium;
    padding-top: 1%;
  }

  .answers {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3%;
    margin: auto;
    width: 60%;
    //max-width: 800px;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ul {
    width: 33%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin: 16px auto;

    li {
        text-align: left;
        font-size: $font-size-big;
    }
  }
}
