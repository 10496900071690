@import 'src/styles/global.scss';

.button {
    button, .primary {
        background-color: $primary-color;
        color: $white;
        font-size: $default-font-size;
        font-weight: 400;
        border-radius: $button-border-radius;
        border: none;
        padding: 0px 20px;
        display: flex;

        &:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
    }
    .disabled {
        background-color: $primary-light;
        color: $white;
        font-weight: 400;
        border-radius: $button-border-radius;
        border: none;
        padding: 0px 20px;
        display: flex;

        &:hover {
            cursor: not-allowed;
            transform: scale(1.01);
        }
    }
    svg {
        margin: auto;
        margin-left: 15px;
    }
}
