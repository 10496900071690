@import '../../styles/global.scss';

.main {
  font-size: $font-size-big;
  max-height: 65vh;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 2px;
  padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
}

.child_text {
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding-top: 50px;
  padding-right: 2vw;
  font-family: $font-family;
  //font-size: 18pt;

  p {
    text-align: justify;
  }
}

.child_image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $font-family;
  //padding-top: 50px;
  //padding-right: 256px;
  width: 32%;
  img {
    margin-top: 32px;
    border-radius: 16px;
  }
}

.bottom {
  font-size: $font-size-big;
  font-family: $font-family;
  padding-top: 4vh;
}

footer {
  font-size: $font-size-small;
  padding-top: 64px;
}

