@import 'src/styles/global.scss';

.main {
  font-size: $default-font-size;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2px;
  padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
  width: 100%;
  margin: auto;
}

.tryOutMyLuck {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 50px;
  width: 20%;

  button {
    padding: 1vh;
    width: 50%;
    //text-align: center;
    display: flex;

    justify-content: center;
    height: 4vh;
  }
}

.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  margin: 8px;
  font-size: $font-size-medium;

  input {
    width: 100%;
    margin: 4px;
    height: 3vh;
  }
}

.info_text {
  position: relative;
  justify-content: center;
  text-align: center;
  font-size: $font-size-medium;
}

.correct {
  background-color: rgb(238, 247, 238);
  padding-bottom: 64px;
  padding-left: 32px;
  //border: 2px solid green;
}

footer {
  font-size: $font-size-small;
  padding-top: 64px;
}

.parent_answers {
  position: relative;
  font-size: $font-size-tiny;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  margin: auto;
  width: 50%;
}

.answers {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 50px;
  padding-right: 16px;
  width: 100%;

  .question_title {
    font-size: $font-size-small;
  }
}

.answers2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 50px;
  padding-right: 16px;
  width: 90%;
  margin-left: 10%;
}
