@import 'src/styles/global.scss';

.page {
  height: 100%;

  h1 {
    font-size: $font-size-large;
    font-weight: 700;
    margin-bottom: 1rem;

  }
}