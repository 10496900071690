@import '../../styles/global';

.input {
    display: flex;
    
    input {
        margin: auto;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
        font-size: $default-font-size;
        font-family: inherit;
        width: 50%;
    }

    &.notValid {
        input {
            border: 1px solid red;

            &:focus {
                border: 1px solid red;
                outline: red;
            }
        }
    }
}