@import '../../styles/global.scss';

.main {
  font-size: $font-size-big;
  max-height: 80vh;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //margin-top: 2px;
  //padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
  width: 100%;

}

.buttons {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  max-height: 40vh;

  p {
    font-size: 3.5vh;
    font-family: Arial;
    font-weight: bolder;
    padding-bottom: 2vh;
  }

}

.child_image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $font-family;
  //padding-top: 50px;
  //padding-right: 256px;
  width: 50%;
  img {
    //margin-top: 32px;
    //border-radius: 16px;
    width: 75%;
  }
}

.tryOutMyLuckA {
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;

  button {
    width: 100%;
    font-size: 2vh;
    display: flex;
    background: #a2c4c9ff;
    justify-content: center;
    height: 6vh;
    text-align: center;
    vertical-align: center;
    align-items: center;
  }
}

.tryOutMyLuckB {
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;

  button {
    width: 100%;
    font-size: 2vh;
    display: flex;
    background: #45818eff;
    justify-content: center;
    height: 6vh;
    text-align: center;
    vertical-align: center;
    align-items: center;
  }
}

.tryOutMyLuckC {
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;

  button {
    width: 100%;
    font-size: 2vh;
    display: flex;
    background: #38761dff;
    justify-content: center;
    height: 6vh;
    text-align: center;
    vertical-align: center;
    align-items: center;
  }
}

.tryOutMyLuckD {
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;

  button {
    width: 100%;
    font-size: 2vh;
    display: flex;
    background: #274e13ff;
    justify-content: center;
    height: 6vh;
    text-align: center;
    vertical-align: center;
    align-items: center;
  }
}

.RightArrowIcon {
  font-size: 6vh;
  padding-right: 0.25vh;
  padding-left: 0.15vh;
}

