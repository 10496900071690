@import '../../styles/global.scss';


.colorbar {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  font-size: $font-size-small;
  
  .left {
    width: 1.5vw;
    flex-direction: row;

    img {
      width: 1.5vw;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    height: 100%;

    .high {
      order: -1;
      padding-left: 0.5vw;
    }

    .low {
      padding-left: 0.5vw;
      margin-top: auto;
    }
  }
}

.main {
  font-size: $font-size-medium;
  width: 100%;
  margin: 0 auto;

  h2 {
    font-size: $font-size-big;
  }


  .parent {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 2px;
    padding: 8px 32px 0px 0px;
    border-radius: $default-border-radius;
  }

  .child_image {
    flex: 1;
  }
  .child_text {
    font-size: $font-size-medium;
    margin-top: 15%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

 

