@import 'src/styles/global.scss';

.regionalExpertise {
  max-height: calc(90vh - 170px);
  h2 {
    font-size: $font-size-big;
  }

  .subtitle {
    font-size: $font-size-big;
    padding-top: 1%;
  }

  .container {
    display: flex;
    //padding-top: 8px;
    
    .helper_text {
      font-size: $font-size-tiny;
      width: 50%;
      margin-top: 2vh;
      position: absolute; 
      font-style: italic;
    }

    .region {
      //min-width: 400px;
      @media (max-width: 1281px) {
        width: 20vw;
      }

      width: 30%;
    }

    .answers {
      display: flex;
      flex-direction: column;
      flex: 2;
      width: 100%;

      img {
        margin: 0 0 8px 0;
        width: 15px;
        height: 15px;
      }

      .card {
        max-height: 50vh;
        margin: 10px 16px;
        min-width: 150px;
        //max-width: 350px;
        width: 33%;
        
        .group_display {
          height: 1vw;
        }
        
        .cardAnswers {
          overflow-y: auto;
          margin-top: 20px;
          //border-top: 1px solid $primary-color;
          //padding: 20px 12px;
          
          .cardAnswer {
            display: flex;
            gap: 0.5rem;
            height: 2.5vh;
            //margin-bottom: 10px;
          }
        }
      }

      .cardNarrow {
        max-height: 50vh;
        margin: 10px 16px;
        min-width: 150px;
        //max-width: 350px;
        width: 25%;
        
        .group_display {
          height: 1vw;
        }
        
        .cardAnswers {
          overflow-y: auto;
          //border-top: 1px solid $primary-color;
          padding: 20px 12px;
          
          .cardAnswer {
            display: flex;
            gap: 0.5rem;
            height: 2.5vh;
            //margin-bottom: 10px;
          }
        }

      }

      h1 {
        justify-content: center;
        display: flex;
        height: 32px;
        font-size: $font-size-small;
        align-items: center;
      }

      .flex {
        justify-content: center;
        overflow-y: auto;
        display: flex;
        width: 100%;
        height: 55vh;
        display: flex;

        input {
          padding: 0.25rem;
          border: 1px solid #ccc;
          border-radius: 0.25rem;
          font-size: $default-font-size;
          font-family: inherit;
          border: none;
          outline: none;
          border-bottom: 1px solid $primary-color;
          margin-left: 15px;
          margin-right: 8px;
          //max-width: 100px;
          background: #FFFFFF;
        }

        .freetext {
          display: flex;
          flex: 6;
          margin-right: auto;

          p {
            flex: 1;
          }

          input {
            flex: 10;
            width: 100%;
          }
        }

      }
    }
  }
}
