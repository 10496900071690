@import './styles/global.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&family=Roboto:wght@100;300&family=Sofia+Sans:wght@300&display=swap');

body {
    margin: 0px;
}
// scrollbar
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background-color: #aaa;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.leaflet-container {
    border-radius: $default-border-radius;
}

.container {
    height: 100%;
    width: 100%;
    margin: auto;
    overflow: auto;
    font-size: $default-font-size;

    .flex {
        position: relative;
        display: flex;
        height: calc(100vh - 10%);
        width: 100%;
    }

    .noPadding {
        height: 100vh;
    }
}
.tooltip {
    z-index: 99999999999;
    top: 0;
    background-color: white;
}
.App {
    display: flex;
    height: 100%;
    position: relative;
    background-color: $gray;

    .background {
        display: none;
    }
}

html {
    height: 100vh;
}

#root {
    height: 100%;
}
body {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
h1 {
    font-family: $font-family;
    justify-content: center;
    text-align: center;
    font-size: $font-size-big;
}

h2 {
    font-size: $default-font-size;
}

p {
    font-family: $font-family;
    text-align: center;
}
