@import 'src/styles/global.scss';

.regionalExpertise {
  max-height: calc(90vh - 170px);
  h2 {
    font-size: $font-size-big;
  }

  .container {
    display: flex;
    //padding-top: 16px;

    .region {
      //min-width: 400px;
      @media (max-width: 1281px) {
        width: 20vw;
      }

      width: 30%;
    }

    .answers {
      display: flex;
      flex-direction: column;
      flex: 2;
      width: 100%;

      .card {
        max-height: 50vh;
        margin: 10px 16px;
        min-width: 200px;
        //max-width: 400px;
        width: 33%;
        
        .cardAnswers {
          //overflow-y: auto;
          //border-top: 1px solid $primary-color;
          padding: 0 12px;
          height: 90%;

          p {
            height: $font-size-medium;
          }

          .cardAnswer {
            display: flex;
            gap: 0.5rem;
            height: 2.5vh;
          }
        }
      }

      .cardNarrow {
        max-height: 50vh;
        margin: 10px 16px;
        min-width: 200px;
        //max-width: 400px;
        width: 25%;
        
        .cardAnswers {
          //overflow-y: auto;
          //border-top: 1px solid $primary-color;
          padding: 0 12px;
          height: 90%;


          p {
            height: $font-size-medium;
          }

          .cardAnswer {
            display: flex;
            gap: 0.5rem;
            height: 2.5vh;
          }
        }
      }

      h1 {
        justify-content: center;
        display: flex;
        height: 32px;
        font-size: $font-size-small;
        align-items: center;

      }

      .flex {
        overflow-y: auto;
        display: flex;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        input {
          padding: 0.25rem;
          border: 1px solid #ccc;
          border-radius: 0.25rem;
          font-size: $font-size-small;
          font-family: inherit;
          border: none;
          outline: none;
          border-bottom: 1px solid $primary-color;
          margin-left: 15px;
          margin-right: 8px;
          //max-width: 100px;
          background: #FFFFFF;
        }

        .freetext {
          display: flex;
          flex: 6;
          margin-right: auto;


          p {
            flex: 1;
          }

          input {
            flex: 10;
            width: 100%;
          }
        }

      }
    }
  }
}
