@import '../../styles/global.scss';

.main {
  font-size: $default-font-size;
  max-height: 65vh;
}

.parent {
  max-width: 1024px;
  box-shadow: $default-shadow;
  border-radius: $default-border-radius;
  margin: auto;
  padding: 24px 32px;
  position: relative;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: left;

  p {
    text-align: left;
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.child_text {
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-right: 32px;
  //font-size: 18pt;
  width: 50%;
}

.child_table {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-top: 50px;
  //padding-right: 256px;
  width: 50%;
  table {
    width: 100%;
    padding: 8px;
    //border: 1px solid grey;
    border-radius: $default-border-radius;
    box-shadow: $default-shadow;
  }
  
  table tbody tr td {
     border: 1px solid rgb(197, 190, 190);
    padding: 12px;
    border-radius: $default-border-radius;
  }
}

.child_table_lower {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-top: 50px;
  //padding-right: 256px;
  width: 50%;
  table {
    width: 100%;
    padding: 8px;
    margin-top: 32px;
    //border: 1px solid grey;
    border-radius: $default-border-radius;
    box-shadow: $default-shadow;
  }
  
  table tbody tr td {
     border: 1px solid rgb(197, 190, 190);
    padding: 12px;
    border-radius: $default-border-radius;
  }
}