@import '../../styles/global.scss';

.cover {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: white;

    .sidebar {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        text-align: center;
        margin-top: 0;
        min-width: 250px;
        margin-left: 15px;
        border-radius: $default-border-radius;
        padding: 10px;
        box-shadow: $default-shadow;
        background-color: ghostwhite;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin: 0;
        text-align: start;
        justify-content: baseline;

        input {
            margin: 10px 0;
            width: 100%;
        }
    }

    .button {
        width: fit-content;
        margin: auto;
        
        button {
            height: 50px;
            width: 200px;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }

    .footNote {
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 50vh;
        margin-top: auto;
        margin-left: 5%;
        z-index: 99;
        font-family: $font-family;
        font-size: $font-size-small;
        color: #444;

        p {
            font-family: $font-family;
            margin: 0;
            text-align: left;
        }

    }

    .button {
        bottom: 5%;
        left: 5%;
        z-index: 99;
    }
    
    .pic1 {
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
        transition: transform ease 0.5s, box-shadow ease 0.5s;

        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: translateX(30%);
    }
    .hovered {
        transform: translateX(29%);
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
    }
    .title {
        width: fit-content;
        max-width: 400px;
        margin-left: 6rem;
        padding-top: 5%;
        z-index: 99;

        h1 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-large;
        }

        h2 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-big;
            color: #444;
        }

        h3 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-big;
            color: $primary-color;
        }
    }

}
