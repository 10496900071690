@import '../../styles/global';

.region {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;

  .leaflet-container {
    height: 100%;
  }

  .map {
    height: 50vh;
    max-height: 100%;
    box-shadow: $deep-shadow;
    border-radius: $default-border-radius;
  }
}