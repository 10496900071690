@import 'src/styles/global.scss';

.form {
  border-radius: 5px;
  overflow: auto;
  margin: -5px;
  padding: 2rem;
  font-family: 'Raleway', sans-serif;
  margin-top: 24px;

  .main_title {
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    text-align: left;
    margin: revert;
  }
  .checkbox {
    margin: auto;
    padding-top: 1.5vh;
  }

  button {
    height: 50px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .download {
    margin: auto 100 100 auto;
    div {
      justify-content: end;
      display: flex;
      padding-bottom: 10px;
    }
    button {
      background-color: #7a7a7aff;
      width: 20%;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-top: 1px solid $primary-color;
    padding-top: 1vh;

    .flex {
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
    }

    .next {
      margin: auto 16px;
      margin-right: 0px;
      width: 20%;
    }
  }

  .consent {
    display: flex;
    max-width: 500px;
    align-items: flex-start;
    justify-content: left;
    text-align: left;

    p {
      text-align: left;
      max-width: 80%;
    }

    h1 {
      text-align: left;
    }
  }
}
