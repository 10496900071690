@import '../../styles/global.scss';

.question {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    padding: 20px 50px;
    max-height: calc(100vh - 130px);
    height: fit-content;

    h1 {
        //max-width: 60%;
        text-align: center;
        margin: auto;
    }

    h2 {
        margin: auto;
        width: fit-content;
        color: black;
        //font-size: 16px;
        font-weight: 400;
        text-align: center;
        width: 80%;
        margin: auto;
    }
}
.maxHeight {
    max-height: calc(100vh - 50px);
}
.padding {
    padding: 48px;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.card {
    position: relative;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    height: fit-content;
    border-radius: $default-border-radius;
    min-height: 65vh;
    overflow: auto;
    box-shadow: $default-shadow;
    z-index: 0;

    div:first-of-type {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.25s;
    }
}

.logo {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 65px;
    width: 55px;
    z-index: 99999;
}

.top_left {
    position: absolute;
    max-width: 128px;
    top: 32px;
    left: 32px;
    z-index: -1;
}