.navigation {
    padding-bottom: 15px;

    .buttons {
        display: flex;
        width: 33vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        justify-content: space-between;
    }
}
