@import '../../styles/global';

.region {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px 24px;
  height: auto;

  .leaflet-container {
    height: 100%;
  }

  .map {
    height: 50vh;
    min-width: 500px;
    max-height: 100%;
    box-shadow: $default-shadow;
    border-radius: $default-border-radius;
    display: block;

    .selectionMode {
      position: absolute;
      bottom: 25px;
      right: 12px;
      color: black;
      width: fit-content;
      top: auto;
      font-size: $default-font-size;
      z-index: 9999;
      box-shadow: $default-shadow;
      display: flex;
      flex-direction: column;
      
      button {
        outline: none;
        border: none;
        border-radius: 5px;

        &:hover {
            cursor: pointer;
            background-color: #5f8851;
        }
    }

    .active {
        background-color: #5f8851;

        svg {
            color: white;
        }
    }

    svg {
        margin: 5px;
        font-size: $default-font-size;
    }

      .freeSelectOptions {
        height: fit-content;
        display: flex;
        position: relative;
        background-color: white;
        border-radius: 5px;
      
      }
      
      .close {
        svg {
            color: red;
        }
      }
      
      .accept { 
        svg {
            color: green;
        }
      
        &.disabled {
            svg {
                color: gray;
            }
      
            &:hover {
                background-color: white;
            }
        }
      }
    }
    
  }
}
