@import '../../styles/global';

.question {

    .title {
        display: flex;
        flex-direction: column;
        align-items: left;
    }

    h2 {
        //margin-top: 0;
        //margin-left: 0;
        text-align: center;
    }

    .how_does {
        //padding-left: 2%;
        //padding-top: 64px;
        position: absolute;
        left: 40%;
    }

    .affect {
        position: absolute;
    }

    .vertical {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        position: relative;
        padding: 2% 4%;
        //left: 20%;
        //bottom: 10%;
    }

    .answers {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 2%;
        box-shadow: $default-shadow;
        max-width: 80%;
        margin: auto;
        background-color: ghostwhite;

        .cobenefits {
            display: flex;
            flex-direction: column;
            margin-top: 113px;
            margin-right: 24px;

            .cobenefit {
                display: flex;
                text-align: right;
                margin-left: auto;
                height: 52px;

                img {
                    margin-right: 10px;
                    margin-top: auto;
                    margin-bottom: auto;
                    height: 30px;
                }
            }
        }

        .answer {
            margin: 12px;
            background-color: rgb(53 29 80 / 5%);
            padding: 8px;
            border-radius: $default-border-radius;
            
            p {
                max-width: 160px;
            }

            .radioanswer {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        p {
            // font-weight: 600;
            min-height: 60px;
        }
    }

    .pathways {
        display: flex;
    }

}