@import "../../styles/global.scss";

.container {

    display: flex;
    padding-bottom: 5px;
    justify-content: center;
    width: 100vw;
    height: 2vh;
    position: relative;
    z-index: 10;

    .progressBar {
        width: 33vw;
        height: 10px;
        background-color: #ddd;
        margin: 5px;
        border-radius: 5px;

        .progressText {
            text-align: center;
            line-height: 10px;
            color: white;
        }

        .progress {
            height: 100%;
            border-radius: 5px;
            background-color: $primary-color;
        }
    }
}

.tryOutMyLuckA {
  margin-top: auto;
  margin-bottom: auto;
  width: 7vh;

  button {
    // padding: 0.5vh;
    width: 100%;
    //text-align: center;
    display: flex;
    background: #a2c4c9ff;
    font-size: 1vh;
    align-items: center;
    justify-content: center;
    height: 3vh;
    border-radius: 10px;
  }
}

.tryOutMyLuckB {
  margin-top: auto;
  margin-bottom: auto;
  width: 7vh;

  button {
    // padding: 0.5vh;
    width: 100%;
    //text-align: center;
    display: flex;
    background: #45818eff;
    font-size: 1vh;
    align-items: center;
    justify-content: center;
    height: 3vh;
    border-radius: 10px;
  }
}

.tryOutMyLuckC {
  margin-top: auto;
  margin-bottom: auto;
  width: 7vh;

  button {
    width: 100%;
    //text-align: center;
    display: flex;
    background: #38761dff;
    font-size: 1vh;
    align-items: center;
    justify-content: center;
    height: 3vh;
    border-radius: 10px;
  }
}

.tryOutMyLuckD {
  margin-top: auto;
  margin-bottom: auto;
  width: 7vh;

  button {
    width: 100%;
    //text-align: center;
    display: flex;
    background: #274e13ff;
    font-size: 1vh;
    align-items: center;
    justify-content: center;
    height: 3vh;
    border-radius: 10px;
  }
}

.RightArrowIcon {
  font-size: 3vh;
  padding-right: 0.25vh;
  padding-left: 0.15vh;

  svg {
    vertical-align: 0;
  }
}