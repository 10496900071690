@import '../../styles/global.scss';

.cover {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: white;

    .footNote {
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 300px;
        margin-top: auto;
        margin-left: 5%;
        z-index: 99;
        font-family: $font-family;
        font-size: $default-font-size;
        color: #444;

        p {
            font-family: $font-family;
            margin: 0;
            text-align: left;
        }

    }

    .button {
        bottom: 5%;
        left: 5%;
        z-index: 99;
    }
    
    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: translateX(30%);
    }

    .hovered {
        transform: translateX(29%);
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
    }

    .pic1 {
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
        transition: transform ease 0.5s, box-shadow ease 0.5s;
    }
    
    .title {
        width: fit-content;
        max-width: 400px;
        margin-left: 6rem;
        padding-top: 5%;
        z-index: 99;

        h1 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-large;
        }

        h2 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-big;
            color: #444;
        }

        h3 {
            font-family: $font-family;
            justify-content: left;
            text-align: left;
            font-size: $font-size-big;
            color: $primary-color;
        }
    }

}
