@import '../../styles/global';

.question {
  .main_title {
    padding-bottom: 16px;
  }

  .sub_title {
    font-size: $font-size-big;
    padding-bottom: 16px;
  }

  .answers {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    width: 80%;
    margin: auto;
    //max-height: calc(90vh - 300px);
    //overflow: auto;

    h4 {
      margin: 0;
      text-align: left;
      width: 100%;
      padding: 5px;
    }
  }
  .content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 12px;
    padding-right: 12px;
    //height: 500px;
  }
  .answer {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 0;
    min-width: 250px;
    max-height: 55vh;
    //height: 500px;
    margin-left: 15px;
    border-radius: $default-border-radius;
    padding: 10px;
    box-shadow: $default-shadow;
    background-color: ghostwhite;
    //height: fit-content;

    .title {
      display: flex;
      flex-direction: column;

      .biomIcon {
        background-color: $white;
        width: 100px;
        height: 100px;
        margin: auto;
        border: 3px solid $primary-color;
        justify-content: center;
        text-align: center;
        display: flex;
        border-radius: 50%;
        padding: 5px;
        outline: 5px solid $white;

        img {
          margin: auto;
          height: 70px;
          object-fit: cover;
        }
      }
    }

    .flex {
      display: flex;

      p {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      img {
        margin: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
}
