@import 'src/styles/global.scss';

.submit {
    display: flex;
    max-width: 80%;
    margin: auto;

    .sidebar {
        flex: 1;
        text-align: left;
        box-shadow: $default-shadow;
        padding: 20px;

        p {
            text-align: left;
        }

        .buttons {
            display: flex;
            flex-direction: column;

            .submit {
                width: 100%;
                margin: 0;
                button {
                    height: 50px;
                    width: 200px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }

            input {
                margin: 10px 0;
                width: 100%;
            }
        }
    }

    .container {
        flex: 5;
        padding-top: 10%;

        h2 {
            text-align: center;
        }


        .buttons {
            display: flex;
            margin: auto;
            justify-content: center;
            gap: 20px;

            .goBack {
                margin: 0;
                width: fit-content;
                button {
                    height: 50px;
                    width: 200px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }

            .submit {
                width: fit-content;
                margin: 0;
                button {
                    height: 50px;
                    width: 200px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

    }
}