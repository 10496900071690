@import 'src/styles/global.scss';

.radiogroup {
    position: relative;
    padding: 6px;
    width: fit-content;
    display: flex;
    border-bottom: 1px solid $dark-gray;
    margin: auto;

    .positive {
      color: $blue;

      &.selected {
        color: $light-blue;
      }
    }

    .negative {
      color: $red;

      &.selected {
        color: $light-red;
      }
    }

    .radio {
      display: flex;
      margin: 4px;
      width: 30px;
      height: 30px;

      svg {
        margin: auto;
      }

      &:hover {
        cursor: pointer;
        background-color: $primary-light;
      }
    }

    .selected {
      outline: 1px solid $primary-color;
      background-color: $primary-color;
      color: $white;

      p {
        color: $white;
      }
    }
}