@import '../../styles/global';

.question {
  background-color: #edededff;
  //background: linear-gradient(to top, #edededff, #fff);
  border-radius: 24px;
  padding: 3vh;

  .counter {
    color: rgb(23, 68, 14);
    position: relative;
    font-size: $font-size-small;
    display: flex;
    justify-content: right;
    text-align: left;
  }

  .answers {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    width: 100%;
    margin: auto;
    //max-height: calc(90vh - 300px);
    //overflow: auto;

    h4 {
      margin: 0;
      text-align: left;
      width: 100%;
      padding: 5px;
    }
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    //padding-left: 12px;
    //padding-right: 12px;
    //height: 500px;
  }

  .answer_narrow {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 0;
    width: 15%;
    //min-width: 300px;
    //max-height: 55vh;
    //height: 500px;
    margin: 0 1vw;
    border-radius: $default-border-radius;
    padding: 10px;
    background-color: #f9f9f9ff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    //height: 50%;
    //height: fit-content;
    //align-items: center;
    justify-content: flex-end; 
    
    .title_neither {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      align-items: center; /* Vertically center align items */
      justify-content: center;
      //flex: 1;
      padding-bottom: 100%;
      order: -1;
      h3 {
        font-size: $font-size-medium;
        //margin: 0;
        font-weight: 100;
      }
    }
  }

  .answer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    text-align: center;
    width: 30%;
    //min-width: 300px;
    //max-height: 55vh;
    //height: 500px;
    margin: 0 1vw;
    border-radius: $default-border-radius;
    padding: 10px;
    background-color: #f9f9f9ff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    //height: 50%;
    //height: fit-content;
    justify-content: end;


    .title {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: $font-size-medium;
      }

      img {
        margin: auto;
        height: 70px;
        object-fit: cover;
      }

      .picture {
        img {
          width: 95%;
          height: 200px;
          border-radius: 16px;
        }
      }

      .p_container {
        display: flex;
        flex-direction: row;
        justify-content: left;

        .project_aim_text {
          font-size: $font-size-tiny;
          margin-left: 24px;
        }
  
        .info_icon {
          width: 1vw;
          height: 1vw;
        }
      }

      .project_aim {
        flex-direction: row;
        display: flex;
        justify-content: left;

        img {
          width: 24px;
          height: 24px;
          margin: 0;
        }
      }
    }

    .location {
      flex-direction: row;
      display: flex;
      justify-content: left;
      img {
        width: 24px;
        height: 24px;
        margin: 0;
      }
    }

    .fund_bar_img {
      height: 3vh;
    }

    .fund_bar_h3 {
      margin: 1vh auto;
    }

    .text {
      text-align: left;
      font-size: $font-size-tiny;
      height: 6vh;
      margin-left: 29px;
    }

    .flex {
      display: flex;
      height: 80px;

      p {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      img {
        margin: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
}
