@import '../../styles/global.scss';

.main {
  font-size: $font-size-big;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2px;
  padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
}

.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $font-size-big;
}

.child_image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  img {
    margin: 32px;
    border-radius: 16px;
  }
}

.bottom {
  font-size: $default-font-size;
  padding-top: 64px;
}

footer {
  font-size: $font-size-small;
  padding-top: 64px;
}

