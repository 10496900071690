@import '../../../styles/global.scss';

.radioAnswer {
    background-color: $white;
    border-radius: $default-border-radius;
    border: 1px solid $dark-gray;
    padding: 5px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    box-shadow: $default-shadow;
    height: 4vh;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        border: 1px solid $primary-color;
    }

    .radio {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        margin-right: 10px;

        .checked {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }

    .radioHiddenLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        margin-right: 10px;
        margin: 0 auto;

        .checked {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }
}