@import '../../../styles/global.scss';

.freeTextAnswer {
    background-color: $white;
    border-radius: $default-border-radius;
    border: 1px solid $dark-gray;
    padding: 5px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    box-shadow: $default-shadow;
    height: 4vh;

    input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
        font-size: $default-font-size;
        font-family: inherit;
        border: none;
        width: 60%;
        outline: none;
        border-bottom: 1px solid $primary-color;
        margin-left: 15px;
    }

    &:hover {
        cursor: pointer;
    }

    &.selected {
        border: 1px solid $primary-color;
    }

    .radio {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        margin-right: 10px;

        .checked {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }
}