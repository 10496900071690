@import '../../../styles/global.scss';

.answer {
    background-color: $white;
    border-radius: $default-border-radius;
    outline: 1px solid $dark-gray;
    margin: 5px 15px;
    padding: 10px 20px;
    display: flex;
    min-width: 150px;
    height: 32px;
    align-items: center;
    box-shadow: $default-shadow;

    input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
        font-size: $default-font-size;
        font-family: inherit;
        border: none;
        outline: none;
        width: 100%;
        border-bottom: 1px solid $primary-color;
        margin-left: 15px;
        margin-right: 8px;
    }

    img {
        height: 30px;
        margin-right: 12px;
    }

    svg {
        color: $black;
        margin-right: 12px;
    }

    &:hover {
        cursor: pointer;
        background-color: $white;
        outline: 1px solid $primary-color;

        svg {
            color: $primary-color;
        }
    }

    &.selected {
        background-color: $primary-color;
        outline: 1px solid $primary-color;
        color: $white;

        img {
            filter: brightness(0) invert(1);
        }

        svg {
            color: $white;
        }
    }
}