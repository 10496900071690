@import '../../styles/global';

.tooltipContainer {
  padding: 0;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
  width: fit-content;
  display: flex;
  position: relative;

  .tooltip {
    position: fixed;
    z-index: 1000;
    display: block;
    background-color: $primary-color;
    border-radius: 5px;
    color: $white;
    padding: 5px;
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size-small;
    width: 15%;
    transform: translate(0%, -100%);
    box-shadow: $default-shadow;
    text-align: left;
  }
}
