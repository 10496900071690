@import 'src/styles/global.scss';

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.25s;
}

.alert {
    position: absolute;
    background-color: $white;
    z-index: 9999999;
    top: 2rem;
    opacity: 1;
    animation: fadeIn 0.5s;
    box-shadow: $default-shadow;
    padding: 12px;
    border-radius: $default-border-radius;

    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            margin: auto;
            height: 50px;
            width: 200px;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }

    .header {
        padding-top: 25px;
        
        .icon {
            position: absolute;
            background-color: $white;
            top: -25px;
            left: calc(50% - 25px);
            margin-right: 1rem;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}