@import '../../styles/global';

.map {
    position: relative;

    .leaflet-container {
       height: 100%;
    }

    .mapContainer {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .map_icon {
        width: 25px;
        padding: 4px 0px 2px 4px;
    }

    .map_icon_sub {
        height: 24px;
        margin: 0px;
    }

    .selectedCountry {
        position: absolute;
        top: 0;
        left: 50%;
        color: black;
        font-size: $default-font-size;
        z-index: 9999;
        width: 200px;
        height: calc(100% - 30px);
        padding: 15px;
    }

    .selectionMode {
        position: absolute;
        top: calc(90vh - 200px);
        left: calc(100% - 200px);
        color: black;
        font-size: $default-font-size;
        z-index: 9999;
        box-shadow: $default-shadow;
        display: flex;
        flex-direction: column;

        .buttons {
            display: flex;
        }

        .separator {
            border-left: 1px solid $dark-gray;
            height: 75%;
        }

        .freeSelectOptions {
            height: fit-content;
            display: flex;
            background-color: white;
            border-radius: 5px;
        
        }

        .close {
            svg {
                color: red;
            }
        }

        .accept { 
            svg {
                color: green;
            }

            &.disabled {
                svg {
                    color: gray;
                }

                &:hover {
                    background-color: white;
                }
            }
        }

        button {
            outline: none;
            border: none;
            border-radius: 5px;

            &:hover {
                cursor: pointer;
                background-color: #5f8851;
            }
        }

        .active {
            background-color: #5f8851;

            svg {
                color: white;
            }
        }

        svg {
            margin: 5px;
            font-size: $default-font-size;
        }
    }
}