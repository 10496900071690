@import '../../styles/global.scss';

.checkbox {
  display: flex;
  gap: 10px;
  //padding-top: 0.4vw;

  &:hover {
    cursor: pointer;
  }

  svg {
    //max-width: 16px;
    height: 18px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
  }

  input {
    width: 80%;
    height: 1vh;
  }
  svg {
    margin: auto;
    padding: 2px;
    color: $white;
    border: 1px solid black;
    font-size: $default-font-size;

    @media (max-width: 1281px) {
      width: 10px;
      height: 10px;
    }
    
    width: 1.5vh;
    height: 1.5vh;
    border-radius: 5px;
  }
}

.checkbox.checked {
  svg {
    margin: auto;
    color: $white;
    background-color: $primary-color;
    font-size: $default-font-size;
  }
}
