@import 'src/styles/global.scss';

.nextButton {
    z-index: 999;
    margin-left: auto;
}

.enterLotteryButton {
    z-index: 999;
    //margin: auto;
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20%;

    button {
        width: 100%;
        text-align: center;
        justify-content: center;
        font-size: $font-size-big;
    }
}

.enterSurveyButton {
    z-index: 999;
    position: sticky;
    width: 100%;
    margin-top: 0px;

    button {
        width: 50%;
        justify-content: center;
        vertical-align: center;
        margin-left: 25%;
        font-size: 1.2vw;
    }
}