@import 'src/styles/global.scss';

.backButton {
    z-index: 999;
    button {
        background-color: $white;
        border: 1px solid $dark-gray;
    }
    
    p {
        color: $black;
    }
    svg {
        color: black;
        margin-left: 0;
        margin-right: 15px;
    }
}