@import 'src/styles/global.scss';

.jl95v {
  min-height: 40vh;
}

.main {
  font-size: $default-font-size;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //width: 100%;
  //margin-top: 2px;
  //padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
  height: 100%
}

.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  //padding-top: 50px;
  font-size: 1.6vw;
  width: 100%;
  padding-bottom: 3.5vh;
  padding-top: 3.5vh;
}