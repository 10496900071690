@import '../../styles/global.scss';

.main {
  max-height: 65vh;
  display: flex;
  flex-direction: column;
}

.parent {
  max-width: 80%;
  box-shadow: $default-shadow;
  border-radius: $default-border-radius;
  margin: auto;
  padding: 50px 32px;
  position: relative;
  margin-top: 24px;
  font-size: $font-size-medium;

  p {
    text-align: left;
    padding-bottom: 16px;
  }

  img {
    width: 20px;
  }

  table {
    margin-left: 64px;

    td {
      padding-bottom: 6px;
    }
    
    .description {
      padding-left: 3vw;
    }
  }
}


