@import 'src/styles/global.scss';


.main {
  font-size: $default-font-size;
  width: 60%;
  margin: 15% auto;
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //margin-top: 2px;
  //padding: 8px 32px 0px 0px;
  border-radius: $default-border-radius;
}

.child_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-top: 50px;
  font-size: 1.5vw;
  width: 100%;
}

.bottom {
  font-size: $default-font-size;
  justify-content: center;
  text-align: center;
  padding-top: 64px;
  width: 1024px;
  margin: 0 auto;
}

footer {
  font-size: $font-size-small;
  padding-top: 64px;
}