$primary-color: #437A31;
$primary-light: #BCCCBF;
$gray: #EFEFF5;
$dark-gray: #BDBDBD;
$light-gray: #F5F5F5;
$dark-blue: #1A237E;
$primary-gray: #9E9E9E;
$white: #FFFFFF;
$black: #000000;
$font-family: 'Raleway', sans-serif;
$blue: #1A237E;
$red: #D32F2F;
$light-blue: #9dd6ff;
$light-red: #ff9d9d;

$default-shadow: 0 0 0.25vw 0 rgba(0, 0, 0, 0.25);
$deep-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
$light-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

$default-font-size: 0.9vw;
$font-size-big: 1.2vw;
$font-size-medium: 1.05vw;
$font-size-small: 0.9vw;
$font-size-tiny: 0.8vw;
$font-size-large: 1.4vw;

$default-border-radius: 15px;
$button-border-radius: 15px;

$default-padding: 10px;
